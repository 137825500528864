<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import WaterTax from "./WaterTax/index.vue";
import Neighborhoods from "./Neighborhoods/index.vue";
import TariffPage from "./TariffPage/index.vue";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    WaterTax,
    TariffPage,
    Neighborhoods
  },
  data() {
    return {};
  },
  methods: {},
  created() { },
};
</script>

<template>
  <Layout>
    <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :title="$t('menu.menuitems.tariff.text')" />

    <!-- start personal informations -->
    <b-tabs :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" justified nav-class="nav-tabs-custom"
      content-class="p-3 text-muted">
      <!-- start departments -->
      <b-tab active>
        <template v-slot:title>
          <span :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-none d-sm-inline-block">{{ $t("tariff.tabs.tariff")
          }}</span>
        </template>
        <TariffPage />
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-none d-sm-inline-block">{{
            $t("tariff.tabs.neighborhoods") }}</span>
        </template>
        <Neighborhoods />
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-none d-sm-inline-block">{{
            $t("tariff.tabs.water_tax") }}</span>
        </template>
        <WaterTax />
      </b-tab>
    </b-tabs>

    <!-- end personal informations -->
  </Layout>
</template>
<style scoped></style>
