<script>
import appConfig from "@/app.config";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      tariffs: [],
      Tariff: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchTariff: null,
      SearchMode: false,
      // appprices:[]
    };
  },
  methods: {
    addTariff() {
      var data = {
        title: "popups.addTariff",
        inputs: [
          { model: "name", type: "text", label: "popups.name" },
          { model: "name_en", type: "text", label: "popups.name_en" },

          { model: "fees", type: "number", label: "popups.fees" },

          { model: "benalty", type: "number", label: "popups.penalty" },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("tariff", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.SearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("tariff/search", {
          search: this.searchTariff,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.tariffs = res.data;
        });
    },
    getTariff() {
      this.http.get("tariff").then((res) => {
        this.tariffs = res.data;
      });
    },
    cancelSearchMode() {
      this.searchTariff = "";
      this.SearchMode = false;
      this.get(this.page);
    },
    deleteTariff(tariff) {
      var data = {
        title: "popups.deleteTariff",
        msg: "popups.deletemsg",
      };
      this.popup.confirm(data).then((res) => {
        if (res) {
          console.log(tariff);
          this.http.delete("tariff", tariff.id).then(() => {
            this.get(this.page);
          });
        }
      });
    },
    editTariff(tariff) {
      var data = {
        title: "popups.edittariff",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "popups.name",
            value: tariff.name,
          },
          {
            model: "name_en",
            type: "text",
            label: "popups.name_en",
            value: tariff.name_en,
          },

          {
            model: "fees",
            type: "number",
            label: "popups.fees",
            value: tariff.fees,
          },

          {
            model: "benalty",
            type: "number",
            label: "popups.penalty",
            value: tariff.benalty,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("tariff", tariff.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },

    get(page) {
      console.log(page);
      this.http
        .post("tariff/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.tariffs = res.data;
        });
    },
  },
  created() {
    this.get(1);
    // this.getTariff();
  },
};
</script>

<template>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <div class="search-box chat-search-box" style="width: 250px">
              <div class="position-relative">
                <input
                  :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
                  @keyup="search()"
                  type="text"
                  class="form-control fa-lg text-light"
                  :placeholder="$t('tariff.search')"
                  v-model="searchTariff"
                  style="background-color: #2a3042 !important"
                />
                <i class="bx bx-search-alt search-icon text-light"></i>
                <button
                  @click="cancelSearchMode()"
                  v-if="SearchMode"
                  class="btn-close text-light"
                  style="position: absolute; top: 12px; left: 250px"
                ></button>
              </div>
            </div>
          </div>
          <div class="col-6" style="display: flex; justify-content: flex-end">
            <button
              type="button"
              class="btn btn-light float-end mb-4"
              @click="addTariff()"
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>
          </div>
        </div>
        <div class="table-responsive">
          <table
            class="table table-striped table-hover mb-0"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
          >
            <thead>
              <tr
                class="text-light"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">#</th>
                <th scope="col">{{ $t("tariff.table.name") }}</th>
                <th scope="col">{{ $t("tariff.table.fees") }}</th>
                <th scope="col">{{ $t("tariff.table.penalty") }}</th>
                <th scope="col">{{ $t("tariff.table.created") }}</th>
                <th scope="col">{{ $t("tariff.table.updated") }}</th>
                <th scope="col">{{ $t("tariff.table.operations") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="" v-for="(tariff, index) in tariffs" :key="tariff">
                <td>{{ index + 1 }}</td>
                <td>
                  {{
                    $i18n.locale == "ar"
                      ? tariff.name
                      : tariff.name_en
                      ? tariff.name_en
                      : tariff.name
                  }}
                </td>
                <td>{{ tariff.fees }}</td>
                <td>{{ tariff.benalty }}</td>
                <td>{{ tariff.created.split("T")[0] }}</td>
                <td>{{ tariff.updated.split("T")[0] }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-primary me-2 btn-sm"
                    @click="editTariff(tariff)"
                  >
                    {{ $t("popups.edit") }}
                  </button>
                  <a
                    class="btn btn-danger btn-sm me-2"
                    href="javascript:void(0)"
                    role="button"
                    @click="deleteTariff(tariff)"
                    >{{ $t("popups.delete") }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <!--   Apps  Pagination     -->
  <ul class="pagination pagination-rounded justify-content-center mb-2">
    <li class="page-item" :class="{ disabled: page == 1 }">
      <a
        class="page-link"
        href="javascript: void(0);"
        @click="get(page - 1)"
        aria-label="Previous"
      >
        <i class="mdi mdi-chevron-left"></i>
      </a>
    </li>
    <li
      class="page-item"
      :class="{ active: p == page }"
      v-for="p in tot_pages"
      :key="p"
    >
      <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
        p
      }}</a>
    </li>

    <li class="page-item" :class="{ disabled: page == total_pages }">
      <a
        class="page-link"
        href="javascript: void(0);"
        @click="get(page + 1)"
        aria-label="Next"
      >
        <i class="mdi mdi-chevron-right"></i>
      </a>
    </li>
  </ul>
  <!--end-->
</template>
