<script>
import store from "@/state/store";
var state = store._modules.root._children.auth.state;

export default {
  data() {
    return {
      waterTax: [],
      searchModel: "",
      state: state,
    };
  },
  methods: {
    getWaterTax() {
      this.http.get("tariff/water-tax").then((res) => {
        this.waterTax = res.data;
      });
    },
    editWaterTax(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "tariff.table.name",
            value: app.name,
          },

          {
            model: "name_en",
            type: "text",
            label: "tariff.table.name_en",
            value: app.name_en,
          },

          {
            model: "value",
            type: "number",
            label: "tariff.table.value",
            value: app.value,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("tariff/water-tax", app.id, obj).then(() => {
                this.getWaterTax();
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
  },
  created() {
    this.getWaterTax();
  },
};
</script>

<template>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="row mt-4"></div>

        <div class="table-responsive">
          <table
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="table table-striped table-hover mb-0"
          >
            <thead>
              <tr
                class="text-light"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">#</th>
                <th scope="col">{{ $t("tariff.table.name") }}</th>
                <th scope="col">{{ $t("tariff.table.value") }}</th>
                <th scope="col">{{ $t("tariff.table.operations") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(app, index) in waterTax" :key="app" class="">
                <td>{{ index + 1 }}</td>
                <td>{{ $i18n.locale == "ar" ? app?.name : app?.name_en }}</td>
                <td>{{ app?.value }}</td>
                <td class="d-flex">
                  <a
                    class="btn btn-primary mx-1"
                    @click="editWaterTax(app)"
                    href="javascript: void(0);"
                    role="button"
                    >{{ $t("popups.edit") }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- end  table -->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
